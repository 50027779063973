import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input'

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function AddNewAppointmentModal(props) {
    // params
    const { dashboardData, 
            appointmentStartTime, 
            selectedEmployeeID, 
            selectedServiceID, 
            show, 
            selectedDate, 
            onHide, 
            setSuccessMessage ,
            addNewAppointmentToDashboardData
    } = props;
    const { t } = useTranslation();

    // get service duration for selected service
    const service = dashboardData.services.find((service) => service.serviceID === selectedServiceID);

    // set state for new appointment
    const defaultAppointment = {
        serviceID: 0,
        employeeID: 0,
        storeID: 0,
        appointmentStartTime: "",
        appointmentEndTime: "",
        customerFirstName: "",
        customerLastName: "",
        customerEmail: "",
        customerPhoneNumber: "",
        appointmentStatus: "pending"
    }
    const [newAppointment, setNewAppointment] = useState(defaultAppointment);
    // set state for errors
    const [errors, setErrors] = useState([]);

    const handleOnChangeAddNewAppointment = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const newAppointmentObj = { ...newAppointment, [name]: value };
        // set appointment start time
        const startTime = new Date(selectedDate);
        startTime.setUTCHours(
            appointmentStartTime.getHours().toString(),
            appointmentStartTime.getMinutes().toString()
        );
        startTime.setSeconds(0);
        newAppointmentObj.appointmentStartTime = startTime.toISOString().slice(0, 19).replace('T', ' ');
        // set appointment end time
        const endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + service.serviceDuration);
        newAppointmentObj.appointmentEndTime = endTime.toISOString().slice(0, 19).replace('T', ' ');
        newAppointmentObj.serviceID = selectedServiceID;
        newAppointmentObj.employeeID = selectedEmployeeID;
        newAppointmentObj.storeID = dashboardData.store.storeID;
        // set new appointment state;
        if(validateForm(newAppointmentObj)){
            setErrors([]);
        }
        setNewAppointment(newAppointmentObj);
        
    }
    const handlePhoneChange = (value) => {
        const newAppointmentObj = { ...newAppointment, customerPhoneNumber: value };
        setNewAppointment(newAppointmentObj);
        validateForm(newAppointmentObj);
    }
    const submitAddNewAppointment = (e) => {
        e.preventDefault();
        // post new appointment to database
        callAPIToAddNewAppointment();
    }
    const callAPIToAddNewAppointment = async () => {
        if(!validateForm(newAppointment)){
            return;
        }
        try {
            const response = await fetch(`${API_ENDPOINT_URL}/appointments/add-appointment`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(newAppointment),
            });
            const data = await response.json();
            if(data.status == 'success'){
                setSuccessMessage(t(data.message));
                onHide();
                addNewAppointmentToDashboardData(data.data);
                // reset form
                setNewAppointment(defaultAppointment);
            } else if(data.error){
                setErrors([t('Something went wrong. Please try again later.')]);
            } 
        } catch (error) {
            setErrors([t('Something went wrong. Please try again later.')]);
        }
    }

    const validateForm = (newAppointment) => {
        const errors = [];
        let hasErrors = false;
        for(let field in newAppointment) {
            switch (field) {
                case "customerFirstName":
                    if(newAppointment[field].length < 4){
                        errors.push("First name must be at least 4 characters long");
                        hasErrors = true;
                    }
                    break;
                case "customerLastName":
                    if(newAppointment[field].length < 4){
                        errors.push("Last name must be at least 4 characters long");
                        hasErrors = true;
                    }
                    break;
                case "customerEmail":
                    if (!newAppointment[field].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        errors.push("Email address is invalid");
                        hasErrors = true;
                    }
                    break;
                case "customerPhoneNumber":
                    if (!isValidPhoneNumber(newAppointment[field])) {
                        errors.push("Phone number is invalid");
                        hasErrors = true;
                    }
                    break;
                default:
                    break;
            }
        }
        setErrors(errors);
        return !hasErrors;
    }

    return (
        <div className="add-new-appointment-modal-container">
            { show && (
            <div className="modal fade show" style={{ display: 'block' }} id="addNewAppointmentModal" tabIndex="-1" role="dialog" aria-labelledby="addNewAppointmentModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={submitAddNewAppointment}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addNewAppointmentModalLabel">{t('Add New Appointment')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input type="hidden" name="serviceID" value={selectedServiceID} /> 
                                <input type="hidden" name="employeeID" value={selectedEmployeeID} /> 
                                <input type="hidden" name="storeID" value={dashboardData.store.storeID} /> 
                                <input type="hidden" name="appointmentStartTime" value={appointmentStartTime} /> 
                                <div className="mb-3">
                                    <label htmlFor="customerFirstName" className="form-label">{t('Customer First Name')}</label>
                                    <input type="text" className="form-control" id="customerFirstName" name="customerFirstName" value={newAppointment.customerFirstName} onChange={handleOnChangeAddNewAppointment} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="customerLastName" className="form-label">{t('Customer Last Name')}</label>
                                    <input type="text" className="form-control" id="customerLastName" name="customerLastName" value={newAppointment.customerLastName} onChange={handleOnChangeAddNewAppointment} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="customerPhoneNumber" className="form-label">{t('Customer Phone Number')}</label>
                                    <PhoneInput
                                        defaultCountry="BA"
                                        placeholder={t("Enter phone number")}
                                        value={newAppointment.customerPhoneNumber}
                                        onChange={handlePhoneChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="customerEmail" className="form-label">{t('Customer Email')}</label>
                                    <input type="text" className="form-control" id="customerEmail" name="customerEmail" value={newAppointment.customerEmail} onChange={handleOnChangeAddNewAppointment} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="appointmentStatus" className="form-label">{t('Appointment Status')}</label>
                                    <select className="form-select" aria-label="Default select example" name="appointmentStatus" value={newAppointment.appointmentStatus} onChange={handleOnChangeAddNewAppointment}>
                                        <option value="pending">{t('Pending')}</option>
                                        <option value="confirmed">{t('Confirmed')}</option>
                                        <option value="completed">{t('Completed')}</option>
                                        <option value="cancelled">{t('Cancelled')}</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                {Object.keys(errors).length > 0 &&
                                    Object.entries(errors).map(([fieldName, errorMessage]) => (
                                    <div key={fieldName} className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                ))}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onHide}>{t('Close')}</button>
                                <button type="submit" className="btn btn-primary">{t('Save changes')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}

export default AddNewAppointmentModal;