import React  from 'react';
import HeadData from '../components/HeadData';
import { useTranslation } from 'react-i18next';
const HomePage = () => {
  const { t } = useTranslation();
    return (
      <>
      <HeadData title="This is homepage"/>
      <h1>{t('Welcome')}</h1>
      </>
    );
};

export default HomePage;