import React from "react";
import MyStore from "./content/MyStore";
import WorkingHours from "./content/WorkingHours";
import Employees from "./content/Employees";
import Services from "./content/Services";
import Appointments from "./content/Appointments";
import LanguageSelector from "../LanguageSelector";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

class DashboardContentWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardData: {
                store: {
                    storeID: 0,
                    storeName: "",
                    storeDescription: "",
                    storePhone: "",
                    storeEmail: "",
                },
                employees: [],
                workingHours: {
                    0: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 0,
                        storeID: 0
                    },
                    1: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 1,
                        storeID: 0
                    },
                    2: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 2,
                        storeID: 0
                    },
                    3: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 3,
                        storeID: 0
                    },
                    4: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 4,
                        storeID: 0
                    },
                    5: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 5,
                        storeID: 0
                    },
                    6: {
                        workingHoursID: 0,
                        employeeID: 0,
                        workingHoursStartTime: "",
                        workingHoursEndTime: "",
                        workingHoursDayOfWeek: 6,
                        storeID: 0
                    }
                },
                services: [],
                appointments: [],
                licence: {
                    max_employees: 1, // free version
                    max_services: 3 // free version
                }
            }
        }
    }
    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.licenses && user.licenses.length > 0) {
            for (let i = 0; i < user.licenses.length; i++) {
                // check if licence.pivot.licence_expires_at is not null and if it is not null then check if it is greater than current date
                if (user.licenses[i].pivot.licence_expires_at != null && new Date(user.licenses[i].pivot.licence_expires_at) > new Date()) {
                    // check if max_employees is greater than current values
                    if (user.licenses[i].max_employees > this.state.dashboardData.licence.max_employees) {
                        this.setState({
                            dashboardData: {
                                ...this.state.dashboardData,
                                licence: {
                                    ...this.state.dashboardData.licence,
                                    max_employees: user.licenses[i].max_employees,
                                    max_services: user.licenses[i].max_services
                                }
                            }
                        });
                    }
                }
            }
        }
      
        // get store data
        const storeBody = {
          userID: user.userID
        };
        const storeResponse = await fetch(`${API_ENDPOINT_URL}/stores/get-store-by-user-id`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_TOKEN
          },
          body: JSON.stringify(storeBody)
        });
        if (storeResponse.ok) {
          const storeData = await storeResponse.json();

          if(storeData.store == null){
            return;
          }
      
          // get employees data
          const employeesBody = {
            storeID: storeData.store.storeID // pass storeID as a parameter
          };
          const employeesResponse = await fetch(`${API_ENDPOINT_URL}/employees/get-employees-by-store-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(employeesBody)
          });
          if (employeesResponse.ok) {
            const employeesData = await employeesResponse.json();
            this.setState({
              dashboardData: {
                ...this.state.dashboardData,
                store: storeData.store,
                employees: employeesData.employees
              }
            });
          } else {
            console.log("Getting employees Error: " + employeesResponse.status + " " + employeesResponse.statusText);
          }

          // get services data
            const servicesBody = {
                storeID: storeData.store.storeID // pass storeID as a parameter
            };
            const servicesResponse = await fetch(`${API_ENDPOINT_URL}/services/get-services-by-store-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(servicesBody)
            });
            if (servicesResponse.ok) {
                const servicesData = await servicesResponse.json();
                this.setState({
                    dashboardData: {
                        ...this.state.dashboardData,
                        services: servicesData.services
                    }
                });
            } else {
                console.log("Getting services Error: " + servicesResponse.status + " " + servicesResponse.statusText);
            }

            // get working hours data
            const workingHoursBody = {
                storeID: storeData.store.storeID // pass storeID as a parameter
            };
            const workingHoursResponse = await fetch(`${API_ENDPOINT_URL}/working-hours/get-working-hours-by-store-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(workingHoursBody)
            });
            if (workingHoursResponse.ok) {
                const workingHoursData = await workingHoursResponse.json();
                this.setState({
                    dashboardData: {
                        ...this.state.dashboardData,
                        workingHours: workingHoursData.workingHours
                    }
                });
            } else {
                console.log("Getting working hours Error: " + workingHoursResponse.status + " " + workingHoursResponse.statusText);
            }

            // get appointments data for store
            const appointmentsBody = {
                storeID: storeData.store.storeID, // pass storeID as a parameter
            };
            const appointmentsResponse = await fetch(`${API_ENDPOINT_URL}/appointments/get-appointments-by-store-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(appointmentsBody)
            });
            if (appointmentsResponse.ok) {
                const appointmentsData = await appointmentsResponse.json();
                this.setState({
                    dashboardData: {
                        ...this.state.dashboardData,
                        appointments: appointmentsData.data
                    }
                });
            } else {
                console.log("Getting appointments Error: " + appointmentsResponse.status + " " + appointmentsResponse.statusText);
            }

        } else {
          console.log("Getting store Error: " + storeResponse.status + " " + storeResponse.statusText);
        }
    }

    updateDashboardData = (updatedData) => {
        this.setState({
          dashboardData: {
            ...this.state.dashboardData,
            ...updatedData
          }
        });
    }

    getEmployeeHoursForAllDays = (employeeID) => {
        const workingHours = this.state.dashboardData.workingHours;
        const employeeHours = [];
        // find working hours for employee for all days
        Object.entries(workingHours).forEach(([key, workingHoursObj]) => {
            if(workingHoursObj.employeeID == employeeID){
                employeeHours.push(workingHoursObj);
            }
        });
            
        return employeeHours;
    }

    addWorkingHoursForEmployeeForDay = (addWorkingHours) => {
        const newWorkingHours = {
            workingHoursID: addWorkingHours.workingHoursID,
            employeeID: addWorkingHours.employeeID,
            workingHoursStartTime: addWorkingHours.workingHoursStartTime,
            workingHoursEndTime: addWorkingHours.workingHoursEndTime,
            workingHoursDayOfWeek: addWorkingHours.workingHoursDayOfWeek,
            storeID: addWorkingHours.storeID
        };
        this.setState({
            dashboardData: {
                ...this.state.dashboardData,
                workingHours: {
                    ...this.state.dashboardData.workingHours,
                    [addWorkingHours.workingHoursDayOfWeek]: newWorkingHours
                }
            }
        });
    }

    addNewAppointmentToDashboardData = (appointment) => {
        // check if appointment already exists with same serviceID, employeeID, storeID, appointmentStartTime, appointmentEndTime
        const appointments = this.state.dashboardData.appointments;
        for(let i = 0; i < appointments.length; i++){
            if(appointments[i].serviceID == appointment.serviceID && appointments[i].employeeID == appointment.employeeID && appointments[i].storeID == appointment.storeID && appointments[i].appointmentStartTime == appointment.appointmentStartTime && appointments[i].appointmentEndTime == appointment.appointmentEndTime){
                return;
            }
        }
        // add new appointment to appointments array inside dashboardData
        this.setState({
            dashboardData: {
                ...this.state.dashboardData,
                appointments: [
                    ...this.state.dashboardData.appointments,
                    appointment
                ]
            }
        });
    }
    updateAppointmentData = (updatedAppointment) => {
        const appointments = this.state.dashboardData.appointments;
        for(let i = 0; i < appointments.length; i++){
            if(appointments[i].appointmentID == updatedAppointment.appointmentID){
                appointments[i] = updatedAppointment;
                break;
            }
        }
        this.setState({
            dashboardData: {
                ...this.state.dashboardData,
                appointments: appointments
            }
        });
    }

    render() {

        return (
            <div className="col-auto col-md-9 col-xl-10 px-sm-2 px-0">
                <div className="d-flex justify-content-end">
                    <LanguageSelector/>
                </div>
                <div className="tab-content p-4" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-dashboard" role="tabpanel" aria-labelledby="pills-dashboard-tab">
                        Dashboard content
                    </div>
                    <div className="tab-pane fade" id="pills-appointments" role="tabpanel" aria-labelledby="pills-appointments-tab">
                        <Appointments
                            dashboardData={this.state.dashboardData}
                            getEmployeeHoursForAllDays={this.getEmployeeHoursForAllDays}
                            updateDashboardData={this.updateDashboardData}
                            addNewAppointmentToDashboardData={this.addNewAppointmentToDashboardData}
                            updateAppointmentData={this.updateAppointmentData}
                        />
                    </div>
                    <div className="tab-pane fade" id="pills-mystore" role="tabpanel" aria-labelledby="pills-mystore-tab">
                    <MyStore
                        dashboardData={this.state.dashboardData}
                        updateDashboardData={this.updateDashboardData}
                    />
                    </div>
                    <div className="tab-pane fade" id="pills-employees" role="tabpanel" aria-labelledby="pills-employees-tab">
                        <Employees
                            dashboardData={this.state.dashboardData}
                            updateDashboardData={this.updateDashboardData}
                        />
                    </div>
                    <div className="tab-pane fade" id="pills-working-hours" role="tabpanel" aria-labelledby="pills-working-hours-tab">
                        <WorkingHours
                            dashboardData={this.state.dashboardData}
                            addWorkingHoursForEmployeeForDay={this.addWorkingHoursForEmployeeForDay}
                            getEmployeeHoursForAllDays={this.getEmployeeHoursForAllDays}
                        />
                    </div>
                    <div className="tab-pane fade" id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">
                        <Services
                            dashboardData={this.state.dashboardData}
                            updateDashboardData={this.updateDashboardData}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardContentWrapper;