import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../index.css';

import LanguageSelector from '../components/LanguageSelector';

const Header = (props) => {
  const { t } = useTranslation();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const handleLogout = () => {
    // Clear user local storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    // Redirect to login page or another page
    window.location.href = '/login';
};

useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    setIsUserLoggedIn(true);
  } else {
    setIsUserLoggedIn(false);
  }
}, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">Beramel.com</a>
      <button 
          className="navbar-toggler" 
          type="button" 
          onClick={toggleNav}
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`collapse navbar-collapse ${showNav ? 'show' : ''}`} id="navbarNav">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link" href="/">{t('Home')}</a>
          </li>
          {!isUserLoggedIn && (
            <>
              <li className="nav-item">
                <a className="nav-link" href="/register">{t('Register')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/login">{t('Login')}</a>
              </li>
            </>
          )} 
          {isUserLoggedIn && (
            <>
              <li className="nav-item">
                <a className="nav-link" href="/dashboard">{t('Dashboard')}</a>
              </li>
              <li className="nav-item">
                <a onClick={handleLogout} className="nav-link" href="">{t('Logout')}</a>
              </li>
            </>
          )}

        </ul>
        <LanguageSelector />
      </div>
    </nav>
  );
};

export default Header;
