import React  from 'react';
import Register from "../components/Register";
import HeadData from '../components/HeadData';

const RegisterPage = () => {
    return(
    <>
      <HeadData title="This is Register Page"/>
      <Register/>
    </>
    );
};
  
export default RegisterPage;