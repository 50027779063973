import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function WorkingHours(props){
    const { t } = useTranslation();
    
    const { dashboardData, getEmployeeHoursForAllDays, addWorkingHoursForEmployeeForDay } = props;
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // set state for selected employee
    const [selectedEmployeeID, setSelectedEmployeeID] = useState(0);
    // check if working hours are set for selected employee
    const [workingHoursSet, setWorkingHoursSet] = useState(false);
    // state for success message
    const [success, setSuccess] = useState(false);
    // state for errors
    const [errors, setFormErrors] = useState({});
    // state for working hours for selected employee
    const initalWorkingHours = {};
    // loop through days and set initial working hours
    for(let i = 0; i < 7; i++){
        initalWorkingHours[i] = {
            employeeID: 0,
            workingHoursDay: i,
            storeID: 0,
            workingHoursStartTime: "08:00",
            workingHoursEndTime: "16:00"
        }
    }
    const [employeeWorkingHours, setEmployeeWorkingHours] = useState(initalWorkingHours);

    // Show success message only for few seconds
    useEffect(() => {
        let timeoutId;
    
        if (success) {
          timeoutId = setTimeout(() => {
            setSuccess(false);
          }, 3000);
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [success]);

    const handleEmployeeSelect = (event) => {
        const selectedEmployeeID = parseInt(event.target.value);
        // check if working hours are set for selected employee
        const selectedEmployeeWorkingHours = getEmployeeHoursForAllDays(selectedEmployeeID);
        // set employee selected
        if(selectedEmployeeID > 0){
            // set working hours set for selected employee
            if(selectedEmployeeWorkingHours.length > 0){
                setWorkingHoursSet(true);
                setEmployeeWorkingHours(selectedEmployeeWorkingHours);
            }else{
                setWorkingHoursSet(false);
                const newWorkingHours = initalWorkingHours;
                // loop through days and set initial working hours for selected employee
                for(let i = 0; i < 7; i++){
                    newWorkingHours[i].employeeID = selectedEmployeeID;
                    newWorkingHours[i].storeID = dashboardData.store.storeID;
                }
                setEmployeeWorkingHours(newWorkingHours);
            }
        } 
        setSelectedEmployeeID(selectedEmployeeID);
    }
    const handleWorkingHoursChange = (event) => {
        const dayKey = event.target.dataset.keyDay;
        const timeType = event.target.dataset.timeType;
        const newValue = event.target.value;
        // Create a new object by copying the old state object
        const updatedWorkingHours = { ...employeeWorkingHours };
        if (updatedWorkingHours[dayKey]) {
          updatedWorkingHours[dayKey][`workingHours${timeType}`] = newValue;
        } else {
          updatedWorkingHours[dayKey] = {};
          updatedWorkingHours[dayKey][`workingHours${timeType}`] = newValue;
        }
        if (validateForm()) {
          setEmployeeWorkingHours(updatedWorkingHours);
        }
    };

    const validateForm = () => {
        const errors = {};
        let hasErrors = false;
    
        // Check if an employee is selected
        if (selectedEmployeeID === 0) {
          errors.selectedEmployeeID = t('Please select employee for which you want to set working hours');
          hasErrors = true;
        }
    
        // Check if working hours are set for all days
        for (let i = 0; i < 7; i++) {
          if (!employeeWorkingHours[i] ||
              !employeeWorkingHours[i].workingHoursStartTime ||
              !employeeWorkingHours[i].workingHoursEndTime) {
            errors.workingHours = t('Working hours are not set for all days');
            hasErrors = true;
            break;
          }
        }

        // check if workingHoursStartTime is before workingHoursEndTime
        for (let i = 0; i < 7; i++) {
            if (employeeWorkingHours[i] &&
                employeeWorkingHours[i].workingHoursStartTime &&
                employeeWorkingHours[i].workingHoursEndTime) {
                const startTime = new Date(`01/01/2020 ${employeeWorkingHours[i].workingHoursStartTime}`);
                const endTime = new Date(`01/01/2020 ${employeeWorkingHours[i].workingHoursEndTime}`);
                if(startTime >= endTime){
                    errors.workingHours = `${t(daysOfWeek[i])}: ${t('Working hours start time must be before working hours end time')}`;
                    hasErrors = true;
                    break;
                }
            }
        }
    
        setFormErrors(errors);
        return !hasErrors;
      };

    const callAPIToAddWorkingHours = async (e) => {
        // check if form is valid
        if(!validateForm()){
            return;
        }
        // call API to add working hours for employee for each day in week
        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
            const body = {};
            body.storeID = dashboardData.store.storeID
            body.employeeID = selectedEmployeeID;
            body.workingHoursDayOfWeek = dayOfWeek;
            body.workingHoursStartTime = employeeWorkingHours[dayOfWeek].workingHoursStartTime;
            body.workingHoursEndTime = employeeWorkingHours[dayOfWeek].workingHoursEndTime;
            const response = await fetch(`${API_ENDPOINT_URL}/working-hours/add-working-hours`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            
            if (response.ok) {
                const data = await response.json();
                if(data.errors){
                    // TODO: handle errors
                    console.log('add working hours errors', data.errors);
                } else{
                    // set success message
                    setSuccess(true);
                    // set working hours set to true
                    setWorkingHoursSet(true);
                    // add working hours for selected employee in dashboard data
                    addWorkingHoursForEmployeeForDay(data.workingHours); // TODO: Not working - working hours are not added to dashboard data
                }
                

            } else {
                console.log("Error: " + response.status + " " + response.statusText);
            }
        }
    }

    const callAPIToUpdateWorkingHours = async (e) => {
        // check if form is valid
        if(!validateForm()){
            return;
        }
        // get working hours for selected employee
        const workingHoursForSelectedEmployee = getEmployeeHoursForAllDays(selectedEmployeeID);
        // call API to update working hours for employee for each day in week
        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
            const body = {};
            body.workingHoursID = workingHoursForSelectedEmployee[dayOfWeek].workingHoursID;
            body.workingHoursStartTime = employeeWorkingHours[dayOfWeek].workingHoursStartTime;
            body.workingHoursEndTime = employeeWorkingHours[dayOfWeek].workingHoursEndTime;
            const response = await fetch(`${API_ENDPOINT_URL}/working-hours/update-working-hours`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });

            
            
            if (response.ok) {
                const data = await response.json();
                if(data.errors){
                    console.log('update working hours errors', data.errors);
                } else{
                    // set success message
                    setSuccess(true);
                    // set WorkingHoursSet to true
                    setWorkingHoursSet(true);
                }
            } else {
                console.log("Error: " + response.status + " " + response.statusText);
            }
        }
    }

    const addWorkingHoursForEmployee = (e) => {
        e.preventDefault();
        callAPIToAddWorkingHours(e);
    }
    const updateWorkingHoursForEmployee = (e) => {
        e.preventDefault();
        callAPIToUpdateWorkingHours(e);
    }
    
    return (
        <div className="container">
            { dashboardData.employees.length === 0 && (
                <p className="text-center">No employees found</p>
            )}
            { dashboardData.employees.length > 0 && (
            <div className="row">
                <div className="col-12">
                    <h1>Working Hours</h1>
                    
                    <form onSubmit={workingHoursSet ? updateWorkingHoursForEmployee : addWorkingHoursForEmployee}>
                        <div className="mb-3">
                            <label htmlFor="employeeSelect" className="form-label">{t('Select an employee:')}</label>
                            <select className="form-select" id="employeeSelect" onChange={handleEmployeeSelect}>
                                <option value="0">-</option>
                                {dashboardData.employees.map((employee) => (
                                <option key={employee.employeeID} value={employee.employeeID}>{`${employee.employeeFirstName} ${employee.employeeLastName}`}</option>
                                ))}
                            </select>
                        </div>
                        {selectedEmployeeID > 0 && (
                        <>
                        <div className="mb-3">
                        {daysOfWeek.map((dayName, index) => (
                            <div key={dayName} className="row">
                                <div className="col-md-6">
                                    <label htmlFor={`startTime${dayName}`} className="form-label">{t(`${dayName} Start Time`)}</label>
                                    <input 
                                        type="time" 
                                        step={300} // 5 minutes step
                                        className="form-control"
                                        id={`startTime${dayName}`} 
                                        name={`startTime${dayName}`} 
                                        data-key-day={index} 
                                        data-time-type="StartTime" 
                                        onChange={handleWorkingHoursChange} 
                                        value={employeeWorkingHours[index]?.workingHoursStartTime} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor={`endTime${dayName}`} className="form-label">{t(`${dayName} End Time`)}</label>
                                    <input 
                                        type="time" 
                                        step={300} // 5 minutes step
                                        className="form-control" 
                                        id={`endTime${dayName}`} 
                                        name={`endTime${dayName}`} 
                                        data-key-day={index} 
                                        data-time-type="EndTime"  
                                        onChange={handleWorkingHoursChange} 
                                        value={employeeWorkingHours[index]?.workingHoursEndTime} />
                                </div>
                            </div>
                        ))}
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary green-background">{workingHoursSet ? t('Update Working Hours For Employee') : t('Add Working Hours For Employee') }</button>
                        </div>
                        <div className="mb-3">
                            {success && <div className="alert alert-success" role="alert">{t('You have succesfully updated working hours for employee!')}</div>}
                            {Object.keys(errors).length > 0 &&
                                Object.entries(errors).map(([fieldName, errorMessage]) => (
                                <div key={fieldName} className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>
                            ))}
                            
                        </div>
                        </>
                        )}
                    </form>
                </div>
            </div>
            )}
        </div>
    );
}

export default WorkingHours;