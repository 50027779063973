import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function MyStore(props){
    const { t } = useTranslation();
    // params
    const { dashboardData, updateDashboardData } = props;
    const user = JSON.parse(localStorage.getItem('user'));

    // state
    const [errors, setErrors] = useState({});
    const [formIsValid, setFormValid] = useState(false);
    const [success, setSuccess] = useState(false);

    // Show success message only for few seconds
    useEffect(() => {
        let timeoutId;
    
        if (success) {
          timeoutId = setTimeout(() => {
            setSuccess(false);
          }, 3000);
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [success]);

    // methods
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const newStore = { ...dashboardData.store, [name]: value };
        // update the store in the dashboardData
        const newDashboardData = {
            ...dashboardData,
            store: newStore
        };
        // call the updateDashboardData function to update the state in the parent component
        updateDashboardData(newDashboardData);
        validateForm(newDashboardData.store);
    }

    const handlePhoneChange = (value) => {
        const newDashboardData = {
            ...dashboardData,
            store: {
              ...dashboardData.store,
              storePhone: value
            }
        };
        updateDashboardData(newDashboardData);
        validateForm(newDashboardData.store);
    }

    function validateForm(newStore) {
        let errors = {};
        let formIsValid = true;
        for (let field in newStore) {
            switch (field) {
                case 'storeName':
                    if (newStore[field].length < 3) {
                        formIsValid = false;
                        errors[field] = t('Store Name must be at least 3 characters');
                    }
                break;
                case 'storeDescription':
                    if (newStore[field].length < 3) {
                        formIsValid = false;
                        errors[field] = t('Store Description must be at least 3 characters');
                    }
                    break;
                case 'storeEmail':
                    if (!newStore[field].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        formIsValid = false;
                        errors[field] = t('Email is invalid');
                    }
                    break;
                case 'storePhone':
                    if(typeof newStore[field] === 'string'){
                        if(!isValidPhoneNumber(newStore[field])){
                            formIsValid = false;
                            errors[field] = t('Phone is invalid');
                        }
                    } else if(typeof newStore[field] === 'undefined'){
                        formIsValid = false;
                        errors[field] = t('Phone field is required');
                    }
                    break;
                default:
                break;
            }
        }
        setErrors(errors);
        setFormValid(formIsValid);
    }

    const addStore = async (e) => {
        e.preventDefault();
        if(formIsValid){
            const body = {};
            body.storeName =  dashboardData.store.storeName;
            body.storeDescription = dashboardData.store.storeDescription;
            body.storePhone = dashboardData.store.storePhone;
            body.storeEmail = dashboardData.store.storeEmail;
            body.userID = user.userID;
            const response = await fetch(`${API_ENDPOINT_URL}/stores/add-store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const data = await response.json();
                if(data.errors){
                    setErrors(data.errors);
                } else {
                    // update the store in the dashboardData
                    const newDashboardData = {
                        ...dashboardData,
                        store: data.store
                    };
                    updateDashboardData(newDashboardData);
                    setSuccess(true);
                }
            } else {
                // something went wrong
                console.log('Add store failed');
            }
        } else {
            validateForm(dashboardData.store);
        }
    }

    const updateStore = async (e) => {
        e.preventDefault();
        if(formIsValid){
            const body = {};
            body.storeName = dashboardData.store.storeName;
            body.storeDescription = dashboardData.store.storeDescription;
            body.storePhone = dashboardData.store.storePhone;
            body.storeEmail = dashboardData.store.storeEmail;
            body.storeID = dashboardData.store.storeID;
            const response = await fetch(`${API_ENDPOINT_URL}/stores/update-store`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const data = await response.json();
                if(data.errors){
                    setErrors(data.errors);
                } else {
                    // update the store in the dashboardData
                    const newDashboardData = {
                        ...dashboardData,
                        store: data.store
                    };
                    updateDashboardData(newDashboardData);
                    setSuccess(true); // Set success to true
                }
            } else {
                console.log('Update store failed');
                // something went wrong
            }
        } else {
            validateForm(dashboardData.store);
        }
    }

    return (
        <form onSubmit={dashboardData.store.storeID === 0 ? addStore : updateStore} className="needs-validation">
            {dashboardData.store.storeID === 0 && 
            <div className="alert alert-primary" role="alert">
                {t('You did not setup your store yet. Please setup your store to be able to create appointments.')}
            </div> 
            }
            <div className="mb-3">
                <label htmlFor="storeName" className="form-label">{t('Store Name')}</label>
                <input type="text" className="form-control" id="storeName" name="storeName" onChange={handleInputChange} value={dashboardData.store.storeName} />
            </div>
    
            <div className="mb-3">
                <label htmlFor="storeDescription" className="form-label">{t('Store Description')}</label>
                <textarea className="form-control" id="storeDescription" name="storeDescription" onChange={handleInputChange} value={dashboardData.store.storeDescription} ></textarea>
            </div>
            <div className="form-row">
                <div className="input-group mb-3">
                    <div className="form-group col-md-11">
                    <label htmlFor="storeEmail" className="form-label">{t('Phone Number')}</label>
                    <PhoneInput
                        defaultCountry="BA"
                        placeholder={t("Enter phone number")}
                        value={dashboardData.store.storePhone}
                        onChange={handlePhoneChange}
                        className="form-control"
                    />
                    </div>
                </div>
            </div>
    
            <div className="mb-3">
                <label htmlFor="storeEmail" className="form-label">{t('Store Email')}</label>
                <input type="email" className="form-control" id="storeEmail" name="storeEmail" onChange={handleInputChange} value={dashboardData.store.storeEmail} />
            </div>
    
            <div className="mb-3">
                <button type="submit" className="btn btn-primary green-background">{dashboardData.store.storeId === 0 ? t('Add Store') : t('Update Store') }</button>
            </div>
    
            <div className="mb-3">
                {success && <div className="alert alert-success" role="alert">{t('You have succesfully updated your store!')}</div>}
                {errors.storeName && <div className="alert alert-danger" role="alert">{t(errors.storeName)}</div>}
                {errors.storeDescription && <div className="alert alert-danger" role="alert">{t(errors.storeDescription)}</div>}
                {errors.storePhone && <div className="alert alert-danger" role="alert">{t(errors.storePhone)}</div>}
                {errors.storeEmail && <div className="alert alert-danger" role="alert">{t(errors.storeEmail)}</div>}
            </div>
        </form>
    );
}

export default MyStore;