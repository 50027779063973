import React  from 'react';
import Login from "../components/Login";
import HeadData from '../components/HeadData';
const LoginPage = () => {
    return (
    <>
      <HeadData title="This is Login Page"/>
      <Login/>
    </>
        
    );
};
  
export default LoginPage;