import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function Services(props) {
    const { t } = useTranslation();
    // params
    const { dashboardData, updateDashboardData } = props;

    // Add new service state
    const [formAddServiceIsValid, setFormAddServiceIsValid] = useState(false);
    const [newService, setNewService] = useState({
        serviceName: "",
        serviceDuration: "",
        servicePrice: ""
    });
    const [newServiceErrors, setNewServiceErrors] = useState({});
    // Edit service state
    const [editFormServiceIsValid, setEditFormServiceIsValid] = useState(false);
    const [editService, setEditService] = useState({
        serviceName: "",
        serviceDuration: "",
        servicePrice: ""
    });
    const [editServiceErrors, setEditServiceErrors] = useState({});

    // Edit service modal
    const [displayEditServiceModal, setDisplayEditServiceModal] = useState(false);


    // methods

    // Validate form
    function validateForm(newService, type='add'){
        let errors = {};
        let formIsValid = true;
        for (let field in newService) {
            switch (field) {
                case "serviceName":
                    if(newService[field].length < 4){
                        formIsValid = false;
                        errors[field] = "Service name must be at least 3 characters";
                    }
                    break;
                case "serviceDuration":
                    if(newService[field] < 1){
                        formIsValid = false;
                        errors[field] = "Service duration should be at least one minute";
                    } else if(newService[field] > 480){
                        formIsValid = false;
                        errors[field] = "Service duration should be less than 8 hours";
                    }
                    break;
                case "servicePrice":
                    if(newService[field] < 1){
                        formIsValid = false;
                        errors[field] = "Service price is required";
                    }
                    break;
                default:
                    break;
            
            }
        }
        if(type === 'add'){
            setFormAddServiceIsValid(formIsValid);
            setNewServiceErrors(errors);
        } else if (type === 'edit'){
            setEditFormServiceIsValid(formIsValid);
            setEditServiceErrors(errors);
        }
    }
    // Add new service
    const handleNewServiceChange = (e) => {
        const { name, value } = e.target;
        const newServiceChanged = { ...newService, [name]: value };
        setNewService(newServiceChanged);
        validateForm(newServiceChanged);
    }
    const addService = async (e) => {
        e.preventDefault();
        if (dashboardData && dashboardData.services) {
            if (dashboardData.services.length >= dashboardData.licence.max_services) {
                alert(t('You have reached the maximum number of services for your licence. Please upgrade your licence to add more services.'));
              return;
            }
        } 
        if(formAddServiceIsValid && dashboardData.store){
            const body = {};
            body.storeID = dashboardData.store.storeID;
            body.serviceName = newService.serviceName;
            body.serviceDuration = newService.serviceDuration;
            body.servicePrice = newService.servicePrice;
            console.log(body);
            const response = await fetch(`${API_ENDPOINT_URL}/services/add-service`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if(response.ok){
                const data = await response.json();
                // add new service to dashboardData
                const newDashboardData = {
                    ...dashboardData,
                    services: [...dashboardData.services, data.service]
                };
                // call the updateDashboardData function to update the state in the parent component
                updateDashboardData(newDashboardData);
            } else {
                console.log("add service failed");
            }
        } else {
            validateForm(newService);
        }
    }
    // Delete service
    const deleteService = async (service) => {
        if (window.confirm(t('Are you sure you want to delete this service?'))) {
            const body = {};
            body.serviceID = service.serviceID;
            const response = await fetch(`${API_ENDPOINT_URL}/services/delete-service`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if(response.ok){
                const data = await response.json();
                // delete service from services array inside dashboardData
                const newDashboardData = {
                    ...dashboardData,
                    services: dashboardData.services.filter(service => service.serviceID !== data.service.serviceID)
                };
                // call the updateDashboardData function to update the state in the parent component
                updateDashboardData(newDashboardData);
            } else {
                console.log("delete service failed");
            }
        }
    }
    // Open Edit Service Modal and fill the form with the service data
    const openEditServiceForm = (service) => {
        setDisplayEditServiceModal(true);
        setEditService({
            serviceID: service.serviceID,
            serviceName: service.serviceName,
            serviceDuration: service.serviceDuration,
            servicePrice: service.servicePrice
        });
        setEditServiceErrors({});
    }
    // Edit service
    const handleOnChangeEditService = (e) => {
        const { name, value } = e.target;
        const newServiceEdit = { ...editService, [name]: value };
        setEditService(newServiceEdit);
        validateForm(newServiceEdit, 'edit');
    }
    const submitEditService = async (e) => {
        e.preventDefault();
        if(editFormServiceIsValid){
            const body = {};
            body.serviceID = editService.serviceID;
            body.serviceName = editService.serviceName;
            body.serviceDuration = editService.serviceDuration;
            body.servicePrice = editService.servicePrice;
            const response = await fetch(`${API_ENDPOINT_URL}/services/edit-service`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if(response.ok){
                const data = await response.json();
                // update service in services array inside dashboardData
                // find the service in the dashboardData and update it
                const newDashboardData = {
                    ...dashboardData,
                    services: dashboardData.services.map(service => {
                        if(service.serviceID === data.service.serviceID){
                            return data.service;
                        } else {
                            return service;
                        }
                    })
                };
                // call the updateDashboardData function to update the state in the parent component
                updateDashboardData(newDashboardData);
                // close the modal
                handleClose();
            } else {
                console.log("edit service failed");
            }
        }else{
            console.log('form is not valid');
            validateForm(editService, 'edit');
        }
    }

    // close Edit Services modal

    const handleClose = (e) => {
        setDisplayEditServiceModal(false);
    }

    return (
        <div className="container">
            <div className="row">
              <div className="col">
                <h2>{t('Add New Service')}</h2>
                <form onSubmit={addService}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">{t('Service Name')}</label>
                    <input type="text" className="form-control" id="serviceName" name="serviceName" value={newService.serviceName} onChange={handleNewServiceChange} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="duration" className="form-label">{t('Service Duration (minutes)')}</label>
                    <input 
                        type="number" 
                        className="form-control" 
                        id="serviceDuration" 
                        name="serviceDuration" 
                        min = "1"
                        max="480"
                        value={newService.serviceDuration} 
                        onChange={handleNewServiceChange} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="price" className="form-label">{t('Service Price')}</label>
                    <input 
                        type="number" 
                        className="form-control" 
                        id="servicePrice" 
                        name="servicePrice" 
                        value={newService.servicePrice} 
                        onChange={handleNewServiceChange} />
                  </div>
    
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary green-background">{t('Add Service')}</button>
                  </div>
    
                  <div className="mb-3">
                    {newServiceErrors.serviceName && <div className="alert alert-danger" role="alert">{newServiceErrors.serviceName}</div>}
                    {newServiceErrors.serviceDuration && <div className="alert alert-danger" role="alert">{newServiceErrors.serviceDuration}</div>}
                    {newServiceErrors.servicePrice && <div className="alert alert-danger" role="alert">{newServiceErrors.servicePrice}</div>}
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2>{t('Services')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('Service Name')}</th>
                            <th scope="col">{t('Service Duration')}</th>
                            <th scope="col">{t('Service Price')}</th>
                            <th scope="col">{t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(dashboardData.services) &&
                            dashboardData.services.map((service) => (
                            <tr key={service.serviceID}>
                                <td>{service.serviceName}</td>
                                <td>{service.serviceDuration}</td>
                                <td>{service.servicePrice}</td>
                                <td>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editServiceModal"
                                    onClick={() => openEditServiceForm(service)}
                                >
                                    {t('Edit')}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => deleteService(service)}
                                >
                                    {t('Delete')}
                                </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
              </div>
            </div>
        {displayEditServiceModal && (
        <div className="modal fade show" style={{ display: 'block' }} id="editServiceModal" tabIndex="-1" aria-labelledby="editServiceModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <form onSubmit={submitEditService}>
                <div className="modal-header">
                  <h5 className="modal-title" id="editServiceModalLabel">Edit Service</h5>
                  <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">{t('Service Name')}</label>
                    <input type="text" className="form-control" id="serviceName" name="serviceName" value={editService.serviceName} onChange={handleOnChangeEditService} />
                </div>
                <div className="mb-3">
                    <label htmlFor="duration" className="form-label">{t('Service Duration (minutes)')}</label>
                    <input
                        type="number"
                        className="form-control"
                        id="serviceDuration"
                        name="serviceDuration"
                        min="1"
                        max="480"
                        value={editService.serviceDuration}
                        onChange={handleOnChangeEditService}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="price" className="form-label">{t('Service Price')}</label>
                    <input
                        type="number"
                        className="form-control"
                        id="servicePrice"
                        name="servicePrice"
                        value={editService.servicePrice}
                        onChange={handleOnChangeEditService}
                    />
                </div>
      
                    <div className="mb-3">
                        {editServiceErrors.serviceName && <div className="alert alert-danger" role="alert">{editServiceErrors.serviceName}</div>}
                        {editServiceErrors.serviceDuration && <div className="alert alert-danger" role="alert">{editServiceErrors.serviceDuration}</div>}
                        {editServiceErrors.servicePrice && <div className="alert alert-danger" role="alert">{editServiceErrors.servicePrice}</div>}
                    </div>
                </div>
                <div className="modal-footer">
                  <div className="mb-3">
                    <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-dismiss="modal">{t('Close')}</button>
                  </div>
                  <div className="mb-3">
                      <button type="submit" className="btn btn-primary green-background">{t('Submit')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        )}
    </div>
    );
}

export default Services;