import React from 'react';

const Footer = () => {
const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-light py-3">
      <p className="text-center">&copy; 2022-{currentYear} Beramel.com - All rights reserved</p>
    </footer>
  );
};

export default Footer;